'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

export default function CookieBanner() {
  // Check if the user has already accepted cookies from localStorage
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    // Set the consent flag in localStorage and close the banner
    localStorage.setItem('cookieConsent', 'true');
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="fixed inset-0 z-50">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />

      <div className="fixed inset-0 flex justify-center items-center z-50 w-full p-4">
        <div className="flex items-center justify-center">
          <DialogPanel className="relative transform rounded-lg bg-white px-4 py-6 text-center shadow-xl sm:max-w-xl sm:w-full">
            <div className="text-center sm:text-left">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">Używamy plików cookie</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Nasza strona używa plików cookie w celu poprawy funkcjonalności i analizowania ruchu. Kontynuując korzystanie z serwisu, zgadzasz się na naszą politykę plików cookie. Możesz zmienić swoje ustawienia w każdej chwili.
                </p>
              </div>
            </div>
            <div className="mt-4 flex justify-center sm:flex-row sm:justify-end">
              <button
                type="button"
                onClick={handleAccept}
                className="inline-flex justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              >
                Akceptuję
              </button>
                <a
                href="/legal/cookiesPolicy"
                className="mt-3 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-4 sm:mt-0"
                >
                Dowiedz się więcej
                </a>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
